import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const TechnologiesDetails = ({ TechnologyData }) => {
  return (
    <section className="blog-pg single section-padding pt-0">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-11">
            <div className="post">
              <div className="img">
                {
                  TechnologyData?.node?.banner === null 
                  ?
                  ''// <StaticImage src="../../../static/img/blog/single.jpg" alt="" />
                  : 
                  <img loading ="lazy" className="img-fluid" src={TechnologyData?.node?.banner?.guid} alt={TechnologyData?.node?.title}    />
                }
              </div>
              <div className="content pt-60">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="cont">
                      <div className="spacial">
                        <div>
                          <p
                            dangerouslySetInnerHTML={{
                              __html:
                                TechnologyData?.node?.technologyDescription,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TechnologiesDetails;
