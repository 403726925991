import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";
import { Link } from "gatsby";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { GetProjectsData } from "components/ApiServices/GetProjectsData";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const Works = ({ technology }) => {
  const data = GetProjectsData();

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const [pageLoaded, setPageLoaded] = React.useState(false);

  const [projectData, setProjectData] = useState(null);
  const [projectLength, setProjectLength] = useState(null);

  React.useEffect(() => {
    setPageLoaded(true);
  }, [pageLoaded]);

  const filterByTechnology = (data, technology) => {
    // console.log(data, "data");
    return data.filter((project) => {
      return project.node.technologiesData.nodes.some((tech) => {
        return tech.title.toLowerCase().includes(technology);
      });
    });
  };

  useEffect(() => {
    let projects = filterByTechnology(data, technology);

    if (projects && projects.length > 0) {
      setProjectLength(projects.length);
      setProjectData(projects);
    }
  }, [technology, data]);

  return (
    <section className="work-carousel metro position-re">
      {projectLength && (
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 no-padding">
              <div className="sec-head  text-center">
                <h6 className="wow fadeIn" data-wow-delay=".5s">
                  Projects
                </h6>
              </div>
              <div className="swiper-container">
                <Swiper
                  className="swiper-wrapper"
                  slidesPerView={1}
                  centeredSlides={
                    projectLength && projectLength > 1 ? true : false
                  }
                  loop={projectLength && projectLength > 2 ? true : false}
                  navigation={{
                    prevEl: navigationPrevRef.current,
                    nextEl: navigationNextRef.current,
                  }}
                  onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = navigationPrevRef.current;
                    swiper.params.navigation.nextEl = navigationNextRef.current;
                  }}
                  onSwiper={(swiper) => {
                    setTimeout(() => {
                      swiper.params.navigation.prevEl =
                        navigationPrevRef.current;
                      swiper.params.navigation.nextEl =
                        navigationNextRef.current;

                      swiper.navigation.destroy();
                      swiper.navigation.init();
                      swiper.navigation.update();
                    });
                  }}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  speed={1000}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    767: {
                      slidesPerView: 1,
                      spaceBetween: 0,
                    },
                    991: {
                      slidesPerView: 2,
                    },
                  }}
                >
                  {pageLoaded &&
                    projectData?.map((item, index) => {
                      return (
                        <SwiperSlide className="swiper-slide" key={index}>
                          <div
                            className="content wow noraidus fadeInUp"
                            data-wow-delay=".3s"
                          >
                            <div
                              className="item-img bg-img wow imago"
                              style={{
                                backgroundImage: `url(${item.node.featuredImage.guid})`,
                              }}
                            />
                            <div className="cont">
                              <h6 className="color-font">
                                <a href="#0">{item.node.projectName}</a>
                              </h6>
                              <h4>
                                <Link to={`/work/${item.node.slug}`}>
                                  {item.node.shortDescription}
                                </Link>
                              </h4>
                            </div>
                          </div>
                        </SwiperSlide>
                      );
                    })}
                </Swiper>

                <div
                  ref={navigationNextRef}
                  className="swiper-button-next swiper-nav-ctrl simp-next cursor-pointer"
                >
                  <span className="simple-btn right">Next</span>
                </div>
                <div
                  ref={navigationPrevRef}
                  className="swiper-button-prev swiper-nav-ctrl simp-prev cursor-pointer"
                >
                  <span className="simple-btn">Prev</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Works;
