import React, {useState} from "react";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";
import TechnologiesDetails from "components/technologies-detail";
import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import Works from "components/Works/works";
import SEO from "components/seo/seo";
import LoadingScreen from "components/Loading-Screen";

const TechnologiesDetailsDark = (props) => {
  const TechnologyData = props.pageContext;
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  const url = props.path;

  const segments = url.split("/");
  const technology = segments[segments.length - 2];
  const [theme, setTheme] = useState("Dark");

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });

    var storage = localStorage.getItem("theme");

    if (storage && storage != "") {
      let bodyEl = document.querySelector("body");
      bodyEl.classList.add("themeL");
      setTheme(storage);
    }
  }, [navbarRef]);

  const themeModeChange = (themeMode) => {
    let bodyEl = document.querySelector("body");
    if (themeMode == "Dark") {
      setTheme("themeL");
      bodyEl.classList.add("themeL");
      localStorage.setItem("theme", "themeL");
    } else {
      setTheme("Dark");
      bodyEl.classList.remove("themeL");
      localStorage.removeItem("theme");
    }
  };

  return (
    <DarkTheme>
      <LoadingScreen/>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar 
        nr={navbarRef} 
        lr={logoRef} 
        theme={theme}
        themeModeChange={themeModeChange}
        />
      <PageHeader
        title={TechnologyData?.node?.title}
        // paragraph="All the most current news and events of our creative team."
      />
      <TechnologiesDetails TechnologyData={TechnologyData} />
      <Works technology={technology ? technology : ""} />
      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  const liveUrl = typeof window !== "undefined" ? window.location.href : "";

  return (
    <>
    <SEO
    canonical={liveUrl}
    />
    </>
  );
};

export default TechnologiesDetailsDark;
